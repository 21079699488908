import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _691702c4 = () => interopDefault(import('../pages/theme-3/cashier/index.vue' /* webpackChunkName: "pages/theme-3/cashier/index" */))
const _45508483 = () => interopDefault(import('../pages/theme-3/hold-balance/index.vue' /* webpackChunkName: "pages/theme-3/hold-balance/index" */))
const _daf657b2 = () => interopDefault(import('../pages/theme-3/login.vue' /* webpackChunkName: "pages/theme-3/login" */))
const _0114231a = () => interopDefault(import('../pages/theme-3/my-bonus/index.vue' /* webpackChunkName: "pages/theme-3/my-bonus/index" */))
const _d80b3c16 = () => interopDefault(import('../pages/theme-3/no-support.vue' /* webpackChunkName: "pages/theme-3/no-support" */))
const _06c66646 = () => interopDefault(import('../pages/theme-3/not-found.vue' /* webpackChunkName: "pages/theme-3/not-found" */))
const _22d07aec = () => interopDefault(import('../pages/theme-3/payment-methods/index.vue' /* webpackChunkName: "pages/theme-3/payment-methods/index" */))
const _40bb58ae = () => interopDefault(import('../pages/theme-3/play-game.vue' /* webpackChunkName: "pages/theme-3/play-game" */))
const _d149a9b8 = () => interopDefault(import('../pages/theme-3/promotion/index.vue' /* webpackChunkName: "pages/theme-3/promotion/index" */))
const _c0b64600 = () => interopDefault(import('../pages/theme-3/refer-friend/index.vue' /* webpackChunkName: "pages/theme-3/refer-friend/index" */))
const _4dbd7f55 = () => interopDefault(import('../pages/theme-3/register.vue' /* webpackChunkName: "pages/theme-3/register" */))
const _20ecf464 = () => interopDefault(import('../pages/theme-3/reset-password/index.vue' /* webpackChunkName: "pages/theme-3/reset-password/index" */))
const _5349633f = () => interopDefault(import('../pages/theme-3/history/game.vue' /* webpackChunkName: "pages/theme-3/history/game" */))
const _c95d59be = () => interopDefault(import('../pages/theme-3/history/transaction.vue' /* webpackChunkName: "pages/theme-3/history/transaction" */))
const _73d52abe = () => interopDefault(import('../pages/theme-3/payment-methods/add-bank.vue' /* webpackChunkName: "pages/theme-3/payment-methods/add-bank" */))
const _4ba8e1f3 = () => interopDefault(import('../pages/theme-3/payment-methods/add-crypto-wallet.vue' /* webpackChunkName: "pages/theme-3/payment-methods/add-crypto-wallet" */))
const _fc27840a = () => interopDefault(import('../pages/theme-3/player/messages/index.vue' /* webpackChunkName: "pages/theme-3/player/messages/index" */))
const _6432f9ad = () => interopDefault(import('../pages/theme-3/player/profile.vue' /* webpackChunkName: "pages/theme-3/player/profile" */))
const _73189fa0 = () => interopDefault(import('../pages/theme-3/player/deposits/apply.vue' /* webpackChunkName: "pages/theme-3/player/deposits/apply" */))
const _fb99d8fe = () => interopDefault(import('../pages/theme-3/player/withdrawals/apply.vue' /* webpackChunkName: "pages/theme-3/player/withdrawals/apply" */))
const _6e459de0 = () => interopDefault(import('../pages/theme-3/index.vue' /* webpackChunkName: "pages/theme-3/index" */))
const _9d7cb2e8 = () => interopDefault(import('../pages/theme-3/promotion/_id.vue' /* webpackChunkName: "pages/theme-3/promotion/_id" */))
const _2cacc424 = () => interopDefault(import('../pages/theme-3/articles/_title/_slug.vue' /* webpackChunkName: "pages/theme-3/articles/_title/_slug" */))
const _2b3f41fc = () => interopDefault(import('../pages/theme-3/_type/_id.vue' /* webpackChunkName: "pages/theme-3/_type/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cashier",
    component: _691702c4,
    name: "cashier"
  }, {
    path: "/hold-balance",
    component: _45508483,
    name: "hold-balance"
  }, {
    path: "/login",
    component: _daf657b2,
    name: "login"
  }, {
    path: "/my-bonus",
    component: _0114231a,
    name: "my-bonus"
  }, {
    path: "/no-support",
    component: _d80b3c16,
    name: "no-support"
  }, {
    path: "/not-found",
    component: _06c66646,
    name: "not-found"
  }, {
    path: "/payment-methods",
    component: _22d07aec,
    name: "payment-methods"
  }, {
    path: "/play-game",
    component: _40bb58ae,
    name: "play-game"
  }, {
    path: "/promotion",
    component: _d149a9b8,
    name: "promotion"
  }, {
    path: "/refer-friend",
    component: _c0b64600,
    name: "refer-friend"
  }, {
    path: "/register",
    component: _4dbd7f55,
    name: "register"
  }, {
    path: "/reset-password",
    component: _20ecf464,
    name: "reset-password"
  }, {
    path: "/history/game",
    component: _5349633f,
    name: "history-game"
  }, {
    path: "/history/transaction",
    component: _c95d59be,
    name: "history-transaction"
  }, {
    path: "/payment-methods/add-bank",
    component: _73d52abe,
    name: "payment-methods-add-bank"
  }, {
    path: "/payment-methods/add-crypto-wallet",
    component: _4ba8e1f3,
    name: "payment-methods-add-crypto-wallet"
  }, {
    path: "/player/messages",
    component: _fc27840a,
    name: "player-messages"
  }, {
    path: "/player/profile",
    component: _6432f9ad,
    name: "player-profile"
  }, {
    path: "/player/deposits/apply",
    component: _73189fa0,
    name: "player-deposits-apply"
  }, {
    path: "/player/withdrawals/apply",
    component: _fb99d8fe,
    name: "player-withdrawals-apply"
  }, {
    path: "/",
    component: _6e459de0,
    name: "index"
  }, {
    path: "/promotion/:id",
    component: _9d7cb2e8,
    name: "promotion-id"
  }, {
    path: "/articles/:title?/:slug?",
    component: _2cacc424,
    name: "articles-title-slug"
  }, {
    path: "/:type/:id?",
    component: _2b3f41fc,
    name: "type-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
